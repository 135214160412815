import React from 'react';

function Team() {
    const teamMembers = [
        { name: "Roger Ver", image: "/team1.jpg", title: "CEO and Co-founder" },
        { name: "Jihan Wu", image: "/team2.webp", title: "CTO, Co-founder" },
        { name: "Yuga Labs guy that was fired", image: "/team3.png", title: "CFO" },

    ];

    return (
        <div className="team-grid">
            {teamMembers.map((member, index) => (
                <div key={index} className="team-member">
                    <img src={member.image} alt={member.name} className="team-image" />
                    <p className="team-name">{member.name}</p>
                    <p className="team-title">{member.title}</p>
                </div>
            ))}
        </div>
    );
}

export default Team;
