import React from 'react';
import logo from './logo.png';
import Investors from './components/investors';
import Team from './components/team';
import ButtonRow from './components/buttonRow';
import './App.css';

function ImageGrid() {
  const imagePaths = [
    '1.jpg', '2.webp', '3.jpg', '4.webp', '5.webp',
    '6.png', '7.jpg', '8.png', '10.jpg',
    '11.jpg', '12.png', '13.jpg', '14.png', '15.png',
    '16.jpg', '17.webp', '18.jpg', '19.jpg', '20.jpg', '21.png'
  ];

  const images = imagePaths.map((filename, index) => (
    <img key={index} src={`/${filename}`} alt={`Image ${index + 1}`} className="grid-image" />
  ));

  return <div className="image-grid">{images}</div>;
}

function App() {
  return (
    <div className="App">

      <img src={logo} alt="logo" />

      <div className="headerText">
        <p>Welcome to Bitcoin Cash Fuck Your Mother Inu!</p>
        <p>Let's have a respectful community.</p>
        <p>Please fuck your mother if you want fuck. </p>
      </div>
      <ButtonRow />
      <ImageGrid />
      <h1 className="headerText">Our Investors</h1>
      <Investors />
      <h1 className="headerText">Our Team</h1>
      <Team />
    </div>
  );
}

export default App;
