import React from 'react';

function ButtonRow() {
    return (
        <div className="button-row">
            <a href="https://twitter.com/bitcoincashinu" className="icon-button">
                <img src="/x.png" alt="Close" />
            </a>
            <a href="https://magiceden.io/runes/BITCOIN%E2%80%A2CASH%E2%80%A2FYM%E2%80%A2INU" className="icon-button">
                <img src="/me.png" alt="Me" />
            </a>
            <a href="https://t.me/+xSQmZ3j8nFllZjgx" className="icon-button">
                <img src="/tg.webp" alt="Telegram" />
            </a>
        </div>
    );
}

export default ButtonRow;
