import React from 'react';

function Investors() {
    const investors = [
        { name: "RWA expert (Retard With Autism)", image: "/investor1.png" },
        { name: "1kx Ventures", image: "/investor2.jpg" },
        { name: "Omise Go Capital", image: "/investor3.jpg" },
        { name: "Justin Sun", image: "/investor4.jpg" },
        { name: "2014 crypto guy who's still broke", image: "/investor5.png" },
        { name: "$wzrd/Magic Internet Money investor", image: "/investor6.PNG" }
    ];

    return (
        <div className="investors-grid">
            {investors.map((investor, index) => (
                <div key={index} className="investor">
                    <img src={investor.image} alt={investor.name} className="investor-image" />
                    <p className="investor-name">{investor.name}</p>
                </div>
            ))}
        </div>
    );
}

export default Investors;
